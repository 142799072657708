<template>
  <div class="ticketList">
    <div
      class="ticketList_item"
      v-for="(item, index) in ticketList"
      :key="index"
    >
      <div class="title">
        <div>
          <img
            src="@/assets/images/icon4.png"
            alt=""
            v-if="['我的影票', '影票购买详情'].includes(type)"
          />
          <img
            src="@/assets/images/icon6.png"
            alt=""
            v-if="
              ['儿童科学乐园门票预约记录', '儿童科学乐园门票预约详情'].includes(
                type
              )
            "
          />
          <img
            src="@/assets/images/icon3.png"
            alt=""
            v-if="['门票预约记录', '门票详情'].includes(type)"
          />
          <span>{{ item.title }}</span>
        </div>
      </div>
      <div class="profile_box" @click="isClick ? toDetails(item) : null">
        <img :src="item.cover" alt="" v-if="item.cover" />
        <div class="profile">
          <p v-for="item2 in quantitys" :key="item2.key">
            <span
              :class="
                ['gysj', 'cgsj'].includes(item2.key) ? 'visitingTime' : ''
              "
              >{{ item2.name }}：{{ item[item2.key] }}</span
            >
            <span v-if="item2.right" class="jg_color"
              >￥{{ item[item2.right] }}</span
            >
          </p>
          <template v-if="type === '影票购买详情'">
            <p v-for="item2 in item.showTicketOrderTVOList" :key="item2.typeId">
              <span>{{ item2.typeName }}：{{ item2.nums }}张</span>
              <span>￥{{ item2.prices }}</span>
            </p>
          </template>
          <div v-if="type === '我的影票'" class="ticketing">
            <p>
              <span>购票数：{{ item.votes }}张</span>
              <span
                >票价：<span class="jg_color">￥{{ item.price }}</span></span
              >
            </p>
            <p v-if="item.status === '已取消' && item.refundNum">
              <span
                >退票数：<span class="jg_color"
                  >{{ item.refundNum }}张</span
                ></span
              >
              <span
                >退款：<span class="jg_color"
                  >￥{{ item.refundNum * Number(item.price) }}</span
                ></span
              >
            </p>
          </div>
        </div>
      </div>
      <slot name="btn" :row="item" />
      <div
        v-if="badgeShow"
        class="badge"
        :style="{
          backgroundImage:
            'url(' +
            require(`@/assets/images/badge${
              item.status === 0
                ? '1'
                : item.auditStatus === 0 ||
                  item.auditStatus === 2 ||
                  item.status === 2
                ? '2'
                : '3'
            }.png`) +
            ')',
        }"
      >
        {{
          item.auditStatus === 0
            ? "待审核"
            : item.auditStatus === 2
            ? "已驳回"
            : item.status === 0
            ? "待核销"
            : item.status === 1
            ? "已核销"
            : item.status === 2
            ? "已取消"
            : "已违约"
        }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      type: "",
      toTitle: "",
      api: process.env.VUE_APP_BASE_API + "/profile",
    };
  },
  props: {
    ticketList: {
      type: Array,
      default: () => [],
    },
    quantitys: {
      type: Array,
      default: () => [],
    },
    badgeShow: {
      type: Boolean,
      default: () => false,
    },
    isClick: {
      type: Boolean,
      default: () => true,
    },
  },
  created() {
    this.type = this.$route.query.type;
    if (this.type === "门票预约记录") {
      this.toTitle = "门票详情";
    } else if (this.type === "我的影票") {
      this.toTitle = "影票购买详情";
    } else if (this.type === "儿童科学乐园门票预约记录") {
      this.toTitle = "儿童科学乐园门票预约详情";
    }
  },
  mounted() {},
  methods: {
    //查看详情
    toDetails(item) {
      let data = {
        type: this.toTitle,
        orderNo: item.orderNo,
      };
      this.$router.push({ path: "/ticketDetails", query: data });
    },
  },
};
</script>
<style lang="scss" scoped>
.ticketList {
  .ticketList_item {
    position: relative;
    margin: 0 10px;
    background: #fff;
    border-radius: 5px;
    padding: 15px 20px;
    .title {
      padding-bottom: 10px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #ececec;
      justify-content: space-between;
      > div {
        display: flex;
        align-items: center;
      }
      img {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
      span {
        font-size: 14px;
        font-weight: 500;
        color: rgb(51, 51, 51);
      }
    }
    .profile_box {
      display: flex;
      justify-content: space-between;
      padding: 10px 0;
      > img {
        min-width: 120px;
        width: 120px;
        height: 120px;
        object-fit: cover;
      }
      .profile {
        width: 100%;
        padding-left: 10px;
        > p {
          font-size: 14px;
          color: #666;
          line-height: 24px;
          display: flex;
          justify-content: space-between;
          .small {
            color: #666;
            font-size: 14px;
          }
          .visitingTime {
            font-size: 14px;
            font-weight: 500;
            color: #333;
          }
        }
        .ticketing {
          font-size: 14px;
          color: #666;
          line-height: 24px;
          > p {
            display: flex;
            > span {
              flex: 1;
            }
          }
        }
      }
      .jg_color {
        color: #fd4d4f;
        font-size: 15px;
      }
    }
    .badge {
      position: absolute;
      right: 0;
      top: 0;
      width: 78px;
      height: 30px;
      background-size: cover;
      font-weight: 650;
      font-size: 12px;
      color: #ffffff;
      text-align: center;
      line-height: 30px;
    }
  }
  .ticketList_item + .ticketList_item {
    margin-top: 10px;
  }
}
</style>
