<template>
  <div class="myTicket">
    <van-tabs
      v-model:active="active"
      color="#0B6CF9"
      title-active-color="#0B6CF9"
      @change="tabsChange"
    >
      <van-tab
        :title="item.title"
        :name="item.name"
        v-for="(item, index) in tabs"
        :key="index"
      ></van-tab>
    </van-tabs>
    <div v-show="ticketList.length === 0" class="nothing">
      <img src="@/assets/images/nothing.png" alt="" />
      <span>暂无数据</span>
    </div>
    <van-list
      v-model:loading="loading"
      :immediate-check="false"
      :finished="finished"
      :finished-text="ticketList.length === 0 ? '' : '已经没有更多了哦'"
      @load="onLoad"
    >
      <TicketList :ticketList="ticketList" :quantitys="quantitys">
        <template v-slot:btn="props">
          <div class="btn_box" v-if="active === -3">
            <van-button color="#fd4d4f" plain @click="toRefund(props.row)"
              >退票</van-button
            >
          </div>
          <div class="btn_box" v-if="active === 0">
            <van-button
              color="#fd4d4f"
              plain
              @click="toRefund(props.row)"
              v-if="['门票预约记录', '儿童科学乐园门票预约记录'].includes(type)"
              >整单退票</van-button
            >
            <van-button color="#4bd863" plain @click="toDetails(props.row)"
              >凭证码</van-button
            >
          </div>
          <div class="btn_box" v-if="[-1, 1, 2].includes(active)">
            <van-button plain type="primary" @click="toDetails(props.row)"
              >详情</van-button
            >
          </div>
        </template>
      </TicketList>
    </van-list>
  </div>
</template>
<script>
import TicketList from "@/components/TicketList.vue";
import {
  ticketOrderList,
  ticketRefund,
  playgroundList,
  playgroundRefund,
  movieList,
  movieRefundAll,
} from "@/api/MyTicket";
export default {
  name: "MyTicket",
  data() {
    return {
      type: "",
      isTeamTicket: null,
      active: 0,
      loading: false,
      finished: false,
      ticketList: [],
      pageNum: 1,
      pageSize: 10,
      quantitys: [],
      total: 0,
      getListApi: null,
      refundApi: null,
      tabs: [
        { title: "待核销", name: 0 },
        { title: "已核销", name: 1 },
        { title: "已取消", name: 2 },
        { title: "已违约", name: -1 },
      ],
    };
  },
  components: { TicketList },
  created() {
    this.type = this.$route.query.type;
    if (this.type === "门票预约记录") {
      this.isTeamTicket = false;
    }
    this.setQuantity();
    this.obtainListApi();
    this.getList();
  },
  mounted() {},
  methods: {
    setQuantity() {
      if (this.type === "门票预约记录") {
        let quantitys = [
          { name: "进馆时间", key: "cgsj" },
          { name: "联系人", key: "name" },
          { name: "预约总人数", key: "totalNum" },
        ];
        if (this.active === 1) {
          this.quantitys = quantitys.concat({
            name: "核销时间",
            key: "lastCheckedTime",
          });
        } else if (this.active === 2) {
          this.quantitys = quantitys.concat({
            name: "取消时间",
            key: "refundTime",
          });
        } else {
          this.quantitys = quantitys;
        }
      } else if (this.type === "儿童科学乐园门票预约记录") {
        let quantitys = [
          { name: "游玩时间", key: "ywsj" },
          { name: "联系人", key: "name" },
          { name: "预约总人数", key: "totalNum" },
        ];
        if (this.active === 1) {
          this.quantitys = quantitys.concat({
            name: "核销时间",
            key: "lastCheckedTime",
          });
        } else if (this.active === 2) {
          this.quantitys = quantitys.concat({
            name: "取消时间",
            key: "refundTime",
          });
        } else {
          this.quantitys = quantitys;
        }
      } else if (this.type === "我的影票") {
        this.quantitys = [
          { name: "观影日期", key: "dayTime" },
          { name: "观影时间", key: "gysj" },
          { name: "联系人", key: "contactsName", right: "prices" },
        ];
      }
    },
    getList() {
      let data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        isTeamTicket: this.isTeamTicket,
      };
      if (this.active === -3) {
        data.auditStatus = 0;
      } else if (this.active === -2) {
        data.auditStatus = 2;
      } else {
        data.status = this.active;
      }
      this.getListApi(data)
        .then((res) => {
          if (res.rows.length > 0) {
            res.rows.forEach((item) => {
              if (this.type === "门票预约记录") {
                item.title = "温州科技馆门票";
                item.totalNum = item.totalNum + "人";
                let { endTime, startTime } = item;
                if (endTime && startTime) {
                  item.cgsj =
                    startTime.split(" ")[0] +
                    " " +
                    startTime.split(" ")[1] +
                    "-" +
                    endTime.split(" ")[1];
                } else {
                  item.cgsj = "";
                }
              } else if (this.type === "儿童科学乐园门票预约记录") {
                item.title = "儿童科学乐园门票";
                item.totalNum = item.totalNum + "人";
                let { endTime, startTime } = item;
                if (endTime && startTime) {
                  item.ywsj =
                    startTime.split(" ")[0] +
                    " " +
                    startTime.split(" ")[1] +
                    "-" +
                    endTime.split(" ")[1];
                } else {
                  item.ywsj = "";
                }
              } else if (this.type === "我的影票") {
                let { endTime, startTime } = item;
                item.title = item.filmName;
                item.gysj = startTime + "-" + endTime;
              }
            });
          }
          this.ticketList = this.ticketList.concat(res.rows);
          this.total = res.total;
        })
        .finally(() => {
          this.loading = false;
          if (this.ticketList.length >= this.total) {
            this.finished = true; // 加载结束
            this.ticketList = this.ticketList.filter(
              (obj, index) =>
                this.ticketList.findIndex((item) => item.id === obj.id) ===
                index
            );
          }
        });
    },
    //tabs切换时
    tabsChange() {
      this.setQuantity();
      this.reset();
    },
    onLoad() {
      this.pageNum++;
      this.getList();
    },
    //判断获取调用的api
    obtainListApi() {
      if (this.type === "门票预约记录") {
        this.getListApi = ticketOrderList;
        this.refundApi = ticketRefund;
      } else if (this.type === "儿童科学乐园门票预约记录") {
        this.getListApi = playgroundList;
        this.refundApi = playgroundRefund;
      } else if (this.type === "我的影票") {
        this.getListApi = movieList;
        this.refundApi = movieRefundAll;
      }
    },
    //查看详情
    toDetails(row) {
      let data = {
        orderNo: row.orderNo,
      };
      if (this.type === "门票预约记录") {
        data.type = "门票详情";
      } else if (this.type === "我的影票") {
        data.type = "影票购买详情";
      } else if (this.type === "儿童科学乐园门票预约记录") {
        data.type = "儿童科学乐园门票预约详情";
      }
      this.$router.push({ path: "/ticketDetails", query: data });
    },
    //退票
    toRefund(row) {
      this.$dialog
        .confirm({
          message: "是否确定要退此票,此操作不能返回。",
        })
        .then(() => {
          this.refundApi({ orderNo: row.orderNo }).then((res) => {
            if (res.code === 200) {
              this.$notify({
                type: "success",
                message: res.msg,
              });
              this.reset();
            } else {
              this.$notify({
                type: "danger",
                message: res.msg,
              });
            }
          });
        })
        .catch(() => {});
    },
    reset() {
      this.total = 0;
      this.pageNum = 1;
      this.loading = true;
      this.finished = false;
      this.ticketList = [];
      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
.myTicket {
  background: #f5f6fa;
  min-height: calc(100vh - 46px);
  .van-tabs {
    margin-bottom: 10px;
  }
  .btn_box {
    border-top: 1px solid #ececec;
    text-align: right;
    .van-button {
      width: 70px;
      height: 30px;
      line-height: 30px;
      font-size: 14px;
      padding: 0;
      margin-left: 10px;
    }
  }
  .nothing {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    img {
      width: 60%;
    }
    span {
      font-size: 16px;
      color: #999;
    }
  }
}
</style>
